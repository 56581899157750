import * as USER from './user'

const Environment = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
}

const ReqMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
}
const DOCUMENT_FOR = {
  AGENCY: 'agency',
  LOCATION: 'location',
}

export { USER, Environment, ReqMethods, DOCUMENT_FOR }
