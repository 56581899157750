import WalletcampaignIcon from 'icon/walletcampaign'

function AppLogo({ onToggle }) {
  return (
    <div className='flex items-center justify-start rtl:justify-end'>
      <button
        type='button'
        className='inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200'
        onClick={onToggle}
      >
        <span className='sr-only'>Open sidebar</span>
        <svg
          className='w-6 h-6'
          aria-hidden='true'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            clipRule='evenodd'
            fillRule='evenodd'
            d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
          />
        </svg>
      </button>
      <a href='/' className='flex ms-2 md:me-24'>
        <div className='h-8 me-3'>
          <WalletcampaignIcon />
        </div>
        <span className='self-center text-xl font-semibold sm:text-2xl whitespace-nowrap'>
          Super Admin
        </span>
      </a>
    </div>
  )
}

export default AppLogo
