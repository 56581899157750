const env = {
  API_URL: 'http://localhost:8082/api',
  GHL: {
    V2_BASE_URL: 'https://api.msgsndr.com',
    AGENCY: {
      // dev-SA-Agency (apps@walletcampaigns.com)
      CLIENT_ID: '664dc425fef23846b94fe8ad-lwho043w',
      CLIENT_SECRET: '58aa87a9-8caf-4a39-b5ec-d460617f74cf',
      REDIRECT: 'http://localhost:3000/integrations/crm/associate',
      SCOPE:
        'businesses.readonly businesses.write companies.readonly calendars.readonly calendars.write calendars/events.readonly calendars/events.write calendars/groups.readonly calendars/groups.write calendars/resources.readonly calendars/resources.write campaigns.readonly conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write forms.readonly forms.write invoices.readonly invoices.write invoices/schedule.readonly invoices/schedule.write invoices/template.readonly invoices/template.write links.readonly links.write locations.write locations.readonly locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly medias.readonly medias.write funnels/redirect.readonly funnels/redirect.write opportunities.readonly opportunities.write payments/orders.readonly payments/transactions.readonly payments/subscriptions.readonly products.readonly products.write products/prices.readonly products/prices.write saas/company.read saas/company.write saas/location.read saas/location.write surveys.readonly users.readonly users.write workflows.readonly oauth.write snapshots.readonly oauth.readonly snapshots.write',
    },
    LOCATION: {
      // dev-SA-Location (apps@walletcampaigns.com)
      CLIENT_ID: '664dcbc2fbebeaf2167b6410-lwhp7u6x',
      CLIENT_SECRET: '420dfcae-7e4b-47f4-9350-c3ae869ce394',
      REDIRECT: 'http://localhost:3000/integrations/crm/associate',
      SCOPE:
        'contacts.readonly contacts.write locations.readonly locations/customFields.readonly locations/customFields.write workflows.readonly users.readonly users.write campaigns.readonly conversations/message.readonly conversations/message.write locations/customValues.readonly locations/customValues.write opportunities.readonly opportunities.write forms.readonly links.readonly links.write surveys.readonly locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly',
    },
  },
}

export default env
