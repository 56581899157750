import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

function NavItem({ label, path, icon }) {
  const [active, setActive] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    setActive(pathname == path)
  }, [path, pathname])

  return (
    <li>
      <Link
        to={path}
        className={`flex items-center p-2 text-gray-900 rounded-lg ${
          active ? 'bg-gray-100' : ''
        } hover:bg-gray-100 group`}
      >
        {icon}
        <span className='ms-3'>{label}</span>
      </Link>
    </li>
  )
}

export default NavItem
