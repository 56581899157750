import React from 'react'

function TooltipIcon({ size }) {
  return (
    <svg 
    fill='#000000'
      width={size || '20px'}
      height={size || '20px'}
      viewBox='0 0 22 22'
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 11h10M7 14h4m1.707 6.293 1.707-1.707A2 2 0 0 1 15.828 18H19a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h3.172a2 2 0 0 1 1.414.586l1.707 1.707a1 1 0 0 0 1.414 0z"></path>
        </g>
        </svg>
  )
}

export default TooltipIcon
