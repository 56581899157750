import React, { useState, useEffect, createContext, useContext } from 'react'
import Api from 'utils/api'
import Loader from 'component/loader'
import { useAuth } from './auth'

const initSuperAdminContextPropsState = {
  superAdmin: undefined,
  setSuperAdmin: () => {},
}

const SuperAdminContext = createContext(initSuperAdminContextPropsState)

const useSuperAdmin = () => {
  return useContext(SuperAdminContext)
}

const SuperAdminProvider = ({ children }) => {
  const { logout, auth } = useAuth()
  const [superAdmin, setSuperAdmin] = useState()
  const [loader, setLoader] = useState(true)

  const getSuperAdmin = async () => {
    const { response } = await Api('/super_admin', 'get')
    setLoader(false)
    if (response) setSuperAdmin(response.data)
    else setSuperAdmin({})
  }

  const onLoad = () => {
    getSuperAdmin()
    // if (auth && auth.token) {

    // } else {
    //   logout()
    //   setLoader(false)
    // }
  }

  useEffect(onLoad, [])

  return (
    <SuperAdminContext.Provider
      value={{
        superAdmin,
        setSuperAdmin,
      }}
    >
      {loader ? <Loader /> : children}
    </SuperAdminContext.Provider>
  )
}

export { SuperAdminProvider, useSuperAdmin }
