import DropdownIcon from 'icon/dropdown'
import { useState } from 'react'
import NavItem from './nav-item'

function NavDropdown({ label, path, icon, children }) {
  const [toggleNav, setToggleNav] = useState(false)
  return (
    <li>
      <button
        type='button'
        class='flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100'
        onClick={() => setToggleNav(!toggleNav)}
      >
        {icon}
        <span class='flex-1 ms-3 text-left rtl:text-right whitespace-nowrap'>
          {label}
        </span>
        <DropdownIcon />
      </button>
      {toggleNav && (
        <ul id='dropdown-example' class='py-2 space-y-2 mx-3'>
          {children.map((item) => (
            <NavItem {...item} />
          ))}
        </ul>
      )}
    </li>
  )
}

export default NavDropdown
