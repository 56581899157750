import ProfileButton from './profile-button'

function TopHeader() {
  return (
    <div className='flex items-center'>
      <div className='flex items-center ms-3'>
        <ProfileButton />
      </div>
    </div>
  )
}

export default TopHeader
