import { Environment } from 'enums'
import LocalEnv from './env.local'
import DevEnv from './env.dev'
import ProdEnv from './env.prod'

let env = LocalEnv

// const env = {
//   API_URL: 'http://localhost:8082/api',
//   GHL: {
//     V2_BASE_URL: 'https://api.msgsndr.com',
//     REDIRECT: {
//       SUPER_ADMIN: 'http://localhost:3000/integrations/ghl/sadmin',
//       AGENCY_APP: 'http://localhost:3000/integrations/crm/associate',
//     },
//     AGENCY: {
//       // WalletCampaign SuperAdmin
//       CLIENT_ID: '65b130c03b143e4d852c99ba-lw6c5son',
//       CLIENT_SECRET: 'bc5adee8-297a-4dfc-9a06-84fdc33a0816',
//       SCOPE:
//         'businesses.readonly businesses.write companies.readonly calendars.readonly calendars.write calendars/events.readonly calendars/events.write calendars/groups.readonly calendars/groups.write calendars/resources.readonly calendars/resources.write campaigns.readonly conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write forms.readonly forms.write invoices.readonly invoices.write invoices/schedule.readonly invoices/schedule.write invoices/template.readonly invoices/template.write links.readonly links.write locations.write locations.readonly locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly medias.readonly medias.write funnels/redirect.readonly funnels/redirect.write opportunities.readonly opportunities.write payments/orders.readonly payments/transactions.readonly payments/subscriptions.readonly products.readonly products.write products/prices.readonly products/prices.write saas/company.read saas/company.write saas/location.read saas/location.write surveys.readonly users.readonly users.write workflows.readonly oauth.write snapshots.readonly oauth.readonly snapshots.write',
//     },
//     LOCATION: {
//       // Agency Apps
//       CLIENT_ID: '6246e2fcfc3dabe2d3301bc5-l1gcm0ww',
//       CLIENT_SECRET: '67be1628-cb7c-4530-95a3-a83e60093cd0',
//       SCOPE:
//         'contacts.readonly contacts.write locations.readonly locations/customFields.readonly locations/customFields.write workflows.readonly users.readonly users.write campaigns.readonly conversations/message.readonly conversations/message.write locations/customValues.readonly locations/customValues.write opportunities.readonly opportunities.write forms.readonly links.readonly links.write surveys.readonly locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly',
//     },
//   },
// }

if (process.env.REACT_APP_ENV === Environment.STAGING) {
  env = Object.assign(env, DevEnv)
  // env.API_URL = 'https://dev.api.walletcampaigns.com/api'
  // env.GHL.REDIRECT.SUPER_ADMIN =
  //   'https://dev.superadmin.walletcampaigns.com/integrations/ghl/sadmin'
  // env.GHL.REDIRECT.AGENCY_APP =
  //   'https://dev.superadmin.walletcampaigns.com/integrations/crm/associate'
}

if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env = Object.assign(env, ProdEnv)
  //   env.API_URL = 'https://api.walletcampaigns.com/api'
  //   env.GHL.REDIRECT.SUPER_ADMIN =
  //     'https://superadmin.walletcampaigns.com/integrations/ghl/sadmin'
  //   env.GHL.REDIRECT.AGENCY_APP =
  //     'https://superadmin.walletcampaigns.com/integrations/crm/associate'
}

export default env
