import React, { lazy } from 'react'
import ComponentLoader from './component-loader'
import AdminLayout from 'screens/admin/AdminLayout'
import { Navigate } from 'react-router-dom'
import Associate from 'screens/public/integration/ghl/associate'

const AppSales = ComponentLoader(lazy(() => import('screens/admin/Appsales')))
const Customers = ComponentLoader(
  lazy(() => import('screens/admin/Customers/customers'))
)
const Domain = ComponentLoader(lazy(() => import('screens/admin/Domain')))
const News = ComponentLoader(lazy(() => import('screens/admin/Newsfeed')))
const Product = ComponentLoader(lazy(() => import('screens/admin/Product')))
const DocCategory = ComponentLoader(
  lazy(() => import('screens/admin/Docs/category'))
)
const DocModule = ComponentLoader(lazy(() => import('screens/admin/Docs/docs')))

const WebhookModule = ComponentLoader(
  lazy(() => import('screens/admin/Webhook'))
)
const WebhookLogs = ComponentLoader(
  lazy(() => import('screens/admin/Webhook/view-logs'))
)
const Webhook = ComponentLoader(
  lazy(() => import('screens/admin/Webhook/webhook-list'))
)
const CreateWebhook = ComponentLoader(
  lazy(() => import('screens/admin/Webhook/create/create-webhook'))
)

const TooltipTour = ComponentLoader(
  lazy(() => import('screens/admin/Tooltiptour'))
)
const TourList = ComponentLoader(
  lazy(() => import('screens/admin/Tooltiptour/tooltip'))
)
const CreateTour = ComponentLoader(
  lazy(() => import('screens/admin/Tooltiptour/createtour'))
)
const SassConfiguratorModule = ComponentLoader(
  lazy(() => import('screens/admin/SaaSConfigurator'))
)
const SaasConfig = ComponentLoader(
  lazy(() => import('screens/admin/SaaSConfigurator/saasconfig-main'))
)
const CreatePlan = ComponentLoader(
  lazy(() => import('screens/admin/SaaSConfigurator/saasconfigurator'))
)
const Docs = ComponentLoader(lazy(() => import('screens/admin/Docs/docs/doc')))
const CreateDoc = ComponentLoader(
  lazy(() => import('screens/admin/Docs/docs/create'))
)
const Preview = ComponentLoader(
  lazy(() => import('screens/admin/Docs/docs/preview'))
)

const Crm = ComponentLoader(lazy(() => import('screens/admin/Crm/crm')))
const Settings = ComponentLoader(
  lazy(() => import('screens/admin/Settings/settings'))
)

const Profile = ComponentLoader(
  lazy(() => import('screens/admin/Profile/profile'))
)
const SnapshotModule = ComponentLoader(
  lazy(() => import('screens/admin/Snapshot'))
)
const Team = ComponentLoader(lazy(() => import('screens/admin/Teams')))

const AdminRoutes = [
  {
    path: '',
    element: <AdminLayout />,
    children: [
      {
        path: 'appsales',
        element: <AppSales />,
      },
      {
        path: 'customers',
        element: <Customers />,
      },
      {
        path: 'domain',
        element: <Domain />,
      },
      {
        path: 'news',
        element: <News />,
      },
      {
        path: 'snapshot',
        element: <SnapshotModule />,
      },
      {
        path: 'sassconfigurator',
        element: <SassConfiguratorModule />,
        children: [
          {
            paht: '',
            index: true,
            element: <SaasConfig />,
          },
          {
            path: 'create',
            element: <CreatePlan />,
          },
        ],
      },
      {
        path: 'webhook',
        element: <WebhookModule />,
        children: [
          {
            path: '',
            index: true,
            element: <Webhook />,
          },
          {
            path: 'create-webhook',
            element: <CreateWebhook />,
          },
          {
            path: 'update',
            element: <CreateWebhook />,
          },
          {
            path: 'view-logs',
            element: <WebhookLogs />,
          },
        ],
      },
      {
        path: 'tooltiptour',
        element: <TooltipTour />,
        children: [
          {
            path: '',
            index: true,
            element: <TourList />,
          },
          {
            path: 'create-tour',
            element: <CreateTour />,
          },
          {
            path: 'update',
            element: <CreateTour />,
          },
        ],
      },
      {
        path: 'team',
        element: <Team />,
      },
      {
        path: 'product',
        children: [
          {
            path: '',
            index: true,
            element: <Product />,
          },
        ],
      },
      {
        path: '/agency-docs',
        children: [
          {
            path: '',
            index: true,
            element: <DocCategory />,
          },
          {
            path: 'view',
            element: <DocModule />,
            children: [
              {
                path: '',
                element: <Docs />,
              },
              {
                path: 'preview',
                element: <Preview />,
              },
              {
                path: 'create',
                element: <CreateDoc />,
              },
            ],
          },
        ],
      },
      {
        path: '/agency-docs',
        children: [
          {
            path: '',
            index: true,
            element: <DocCategory />,
          },
          {
            path: 'view',
            element: <DocModule />,
            children: [
              {
                path: '',
                element: <Docs />,
              },
              {
                path: 'preview',
                element: <Preview />,
              },
              {
                path: 'create',
                element: <CreateDoc />,
              },
            ],
          },
        ],
      },
      {
        path: '/location-docs',
        children: [
          {
            path: '',
            index: true,
            element: <DocCategory />,
          },
          {
            path: 'view',
            element: <DocModule />,
            children: [
              {
                path: '',
                element: <Docs />,
              },
              {
                path: 'preview',
                element: <Preview />,
              },
              {
                path: 'create',
                element: <CreateDoc />,
              },
            ],
          },
        ],
      },
      {
        path: 'crm',
        element: <Crm />,
      },
      {
        path: 'settings',
        element: <Settings />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: '',
        index: true,
        element: <Navigate replace to={'/appsales'} />,
      },
      {
        path: '*',
        index: true,
        element: <Navigate replace to={'/appsales'} />,
      },
    ],
  },
  {
    path: 'integrations',
    children: [
      {
        path: 'ghl',
        children: [
          {
            path: 'sadmin',
            element: <Associate />,
          },
        ],
      },
      {
        path: 'crm/associate',
        element: <Associate />,
      },
    ],
  },
  {
    path: '',
    index: true,
    element: <Navigate replace to={'/'} />,
  },
  {
    path: '*',
    index: true,
    element: <Navigate replace to={'/'} />,
  },
]

export default AdminRoutes
