import React from 'react'

function WalletcampaignIcon() {
  return (
    <svg width={36} height={36} viewBox='0 0 432 432'>
      <defs>
        <style>{`.cls-1{fill:url(#linear-gradient);}.cls-2{fill:#292b62;}.cls-3{fill:url(#linear-gradient-2);}.cls-4{fill:url(#linear-gradient-3);}`}</style>
        <linearGradient
          id='linear-gradient'
          x1='165.67'
          y1='283.2'
          x2='321.75'
          y2='283.2'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stop-color='#2198d3' />
          <stop offset='0.26' stop-color='#229dd7' />
          <stop offset='1' stop-color='#65307f' />
        </linearGradient>
        <linearGradient
          id='linear-gradient-2'
          x1='138.22'
          y1='217.03'
          x2='294.3'
          y2='217.03'
          xlinkHref='#linear-gradient'
        />
        <linearGradient
          id='linear-gradient-3'
          x1='110.25'
          y1='148.8'
          x2='266.33'
          y2='148.8'
          xlinkHref='#linear-gradient'
        />
      </defs>
      <g id='Layer_1' data-name='Layer 1'>
        <g id='Main_Icon' data-name='Main Icon'>
          <path
            class='cls-1'
            d='M166.24,340.4,214.16,220a8.05,8.05,0,0,1,7.48-5.07H313.7a8,8,0,0,1,7.44,11.11L271.68,346.43a8,8,0,0,1-7.44,5H173.72A8,8,0,0,1,166.24,340.4Z'
          />
          <path
            class='cls-2'
            d='M317.69,223l-63.45,63.45A34.7,34.7,0,0,1,229.7,296.6H183.64l17.27-43.26L258.12,215h56.25A4.7,4.7,0,0,1,317.69,223Z'
          />
          <path
            class='cls-3'
            d='M146.27,285.25a8,8,0,0,1-7.48-11l17.4-43.7,30.52-76.66a8.05,8.05,0,0,1,7.48-5.07h92.06a8,8,0,0,1,7.44,11.11L244.23,280.26a8,8,0,0,1-7.44,5Z'
          />
          <path
            class='cls-2'
            d='M290.24,156.82l-63.45,63.45a34.7,34.7,0,0,1-24.54,10.16H156.19l17.27-43.27,57.21-38.36h56.25A4.7,4.7,0,0,1,290.24,156.82Z'
          />
          <path
            class='cls-4'
            d='M110.83,206,158.74,85.65a8.05,8.05,0,0,1,7.48-5.07h92.06a8,8,0,0,1,7.44,11.1L216.26,212a8,8,0,0,1-7.44,5H118.3A8,8,0,0,1,110.83,206Z'
          />
        </g>
      </g>
    </svg>
  )
}

export default WalletcampaignIcon
