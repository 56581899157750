import React, { useState } from 'react'
import TopHeader from './top-header'
import NavItem from './nav-item'
import NavDropdown from './nav-dropdown'
import AppLogo from './app-logo'
import HomeIcon from 'icon/home'
import NewsFeedIcon from 'icon/news'
import { Link } from 'react-router-dom'
import DomainIcon from 'icon/domainIcon'
import ProductIcon from 'icon/producticon'
import DocIcon from 'icon/docIcon'
import CrmIcon from 'icon/crmIcon'
import CustomerIcon from 'icon/chart'
import SnapshotIcon from 'icon/snapshot'
import TeamIcon from 'icon/teamicon'
import WebhookIcon from 'icon/webhookicon'
import TooltipIcon from 'icon/tooltip'
import BarChartIcon from 'icon/barchart'

const menu = [
  {
    label: 'App Sales',
    path: '/appsales',
    icon: <HomeIcon />,
  },
  {
    label: 'Customers',
    path: '/customers',
    icon: <CustomerIcon />,
  },
  {
    label: 'Domain',
    path: '/domain',
    icon: <DomainIcon />,
  },
  {
    label: 'News',
    path: '/news',
    icon: <NewsFeedIcon />,
  },
  {
    label: 'Product',
    path: '/product',
    icon: <ProductIcon />,
  },
  {
    label: 'Agency Docs',
    path: '/agency-docs',
    icon: <DocIcon />,
  },
  {
    label: 'Location Docs',
    path: '/location-docs',
    icon: <DocIcon />,
  },
  {
    label: 'Crm',
    path: '/crm',
    icon: <CrmIcon />,
  },
  {
    label: 'Snapshot',
    path: '/snapshot',
    icon: <SnapshotIcon />,
  },
  {
    label: 'Support',
    path: '/team',
    icon: <TeamIcon />,
  },
  {
    label: 'SaaS Configurator',
    path: '/sassconfigurator',
    icon: <BarChartIcon />,
  },
  {
    label: 'Webhook',
    path: '/webhook',
    icon: <WebhookIcon />,
  },
  {
    label: 'Tooltip Tour',
    path: '/tooltiptour',
    icon: <TooltipIcon />,
  },
]

function Sidebar() {
  const [openSideMenu, setOpenSideMenu] = useState(false)

  return (
    <div>
      <nav className='fixed top-0 z-50 w-full bg-white border-b border-gray-200'>
        <div className='px-3 py-3 lg:px-5 lg:pl-3'>
          <div className='flex items-center justify-between'>
            <AppLogo onToggle={() => setOpenSideMenu(!openSideMenu)} />
            <TopHeader />
          </div>
        </div>
      </nav>
      <aside
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform bg-white border-r border-gray-200 sm:translate-x-0 ${
          openSideMenu ? 'transform-none' : '-translate-x-full'
        }`}
      >
        <div className='px-3 pb-4 overflow-y-auto h-[calc(100vh-140px)] bg-white'>
          <ul className='space-y-2 font-medium'>
            {menu.map((menu_item) =>
              menu_item.children ? (
                <NavDropdown {...menu_item} />
              ) : (
                <NavItem {...menu_item} />
              )
            )}
          </ul>
          <div class='absolute bottom-0 w-full px-4 -ml-3'>
            <Link to={'/settings'}>
              <button
                type='button'
                class='text-white w-full bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
              >
                Settings
              </button>
            </Link>
          </div>
        </div>
      </aside>
    </div>
  )
}

export default Sidebar
